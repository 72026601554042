<template>
  <el-dialog :visible.sync="visible" center class="dialog_main" append-to-body :title="title">
    <el-form ref="productForm" :model="productForm" :rules="rules" label-width="120px">
      <el-form-item label="产品名称：" prop="productName">
        <el-input v-model="productForm.productName"></el-input>
      </el-form-item>
      <el-form-item v-if="productForm.id" label="状态：" prop="state">
        <el-select v-model="productForm.state">
          <el-option value="10" label="启用"></el-option>
          <el-option value="20" label="禁用"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button size="mini" type="primary" @click="saveData">保存</el-button>
      <el-button size="mini" type="primary" @click="visible=false">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'ProductInfo',
  data() {
    return {
      visible: false,
      title: '',
      productForm: {},
      rules: {
        productName: [{ required: true, message: '请输入产品名称' }],
        state: [{ required: true, message: '请选择产品状态' }]
      }
    }
  },
  computed: {
    companyInfo() {
      return this.$store.state.companyInfo
    }
  },
  methods: {
    show(data) {
      if (data.id) {
        this.productForm = { ...data }
        this.title = '修改产品'
      } else {
        this.productForm = {}
        this.title = '新增产品'
      }
      if (this.$refs.productForm) this.$refs.productForm.resetFields()
      this.visible = true
    },
    saveData() {
      this.$refs.productForm.validate(async(valid) => {
        if (valid) {
          const { companyId } = this.companyInfo
          const { id, productName, state } = this.productForm
          const url = id ? 'product/edit' : 'product/add'
          const response = await this.$axios.post(url, { companyId, id, productName, state })
          if (response.code === '0000') {
            this.visible = false
            this.$message.success('操作成功')
            this.$emit('submit')
          }
        }
      })
    }
  }
}
</script>
